import React from 'react'
import { TextContainer } from '../../utils'

const TermsNovember2022 = () => (
    <TextContainer>
        <h1>Algemene voorwaarden</h1>
        <p>Versie 9 november 2022</p>

        {/* Definitions */}
        <h2>Definities</h2>
        <table>
            <thead>
                <tr>
                    <th>Definitie</th>
                    <th>Uitleg</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Jij/jou</td>
                    <td>
                        De (rechts)persoon (zijnde een onderneming) die zich als gebruiker van Tellow heeft aangemeld via de website of de app en daarmee als opdrachtgever een
                        overeenkomst met ons aangaat.{' '}
                    </td>
                </tr>
                <tr>
                    <td>Wij/ons</td>
                    <td>
                        Tellow B.V., statutair gevestigd te Danzigerkade 207, 1013 AP, Amsterdam
                        <br />
                        (KvK: 72550414, Btw: NL859149559B01).
                    </td>
                </tr>
                <tr>
                    <td>Tellow</td>
                    <td>Ons boekhoudprogramma, die via de website en de app wordt aangeboden.</td>
                </tr>
                <tr>
                    <td>Diensten</td>
                    <td>De diensten die wij via de website en de app aanbieden, zoals nader aangeduid in artikel 3 van deze algemene voorwaarden.</td>
                </tr>
                <tr>
                    <td>Overeenkomst</td>
                    <td>
                        De overeenkomst van opdracht (bestaande uit deze algemene voorwaarden), die je met ons hebt gesloten door je als gebruiker aan te melden via de website of
                        de app, waarbij jij opdrachtgever bent en wij opdrachtnemer.
                    </td>
                </tr>
                <tr>
                    <td>Website</td>
                    <td>tellow.nl en één of meer van de sub-pagina's hiervan.</td>
                </tr>
                <tr>
                    <td>App</td>
                    <td>De mobiele applicatie van Tellow waarmee je gebruik kan maken van de diensten van Tellow. </td>
                </tr>
            </tbody>
        </table>

        {/* Points */}
        <h2>1. Gebruik van Tellow</h2>
        <ol>
            <li>
                Je kunt gebruiker worden van Tellow door je aan te melden via de website of de app. Na aanmelding wordt je account gecreëerd, wanneer deze klaar is voor gebruik is
                de overeenkomst gesloten en dat moment geldt als ingangsdatum voor het abonnement.{' '}
            </li>
            <li>
                Je kan vervolgens gebruikmaken van de diensten door met een beveiligingscode (de toegangscode en/of magic link) en een toestel in te loggen op de website of in de
                app.
            </li>
            <li>De toegangscode voor de app is een persoonlijke geheime beveiligingscode van vijf cijfers en voor de website een eenmalige inloglink (magic link).</li>
            <li>
                Je dient als gebruiker 18 jaar of ouder te zijn. Daarnaast dien je als gebruiker van Tellow een onderneming te zijn. Het is niet toegestaan om als particulier
                gebruik te maken van de diensten.
            </li>
        </ol>

        <h2>2. Veilig en juist gebruik van Tellow</h2>
        <ol>
            <li>
                Ga zorgvuldig om met je inloggegevens en houd deze geheim. Zorg ervoor dat je toestel en internet- en telecommunicatiediensten en programmatuur die je gebruikt
                geschikt en veilig zijn en blijven voor het gebruik van de diensten. Meld verlies of onrechtmatig gebruik van je toestel of inloggegevens direct bij ons door ons te
                bellen of door ons een bericht te sturen via de chatfunctie of via de website. Indien een derde ongeautoriseerd gebruik maakt van jouw Tellow account dien je dit
                onmiddellijk na ontdekking bij ons te melden.
            </li>
            <li>
                Zorg ervoor dat je toestel vergrendeld is met een code (naast het gebruik van een toegangscode voor de website en app zelf) om te voorkomen dat anderen je toestel
                kunnen gebruiken en sluit de app na gebruik af dan wel log uit op de website. Als je de app niet meer gebruikt of niet meer mag gebruiken, verwijder de app dan van
                het toestel. Dit geldt ook als je je toestel verkoopt of om een andere reden niet meer gebruikt.
            </li>
            <li>
                Wij spannen ons in om de diensten veilig en betrouwbaar te laten zijn. Wij hebben veel maatregelen getroffen om jouw gegevens goed te beschermen. Hierop wordt het
                systeem regelmatig getest.
            </li>
            <li>
                Alle informatie die in Tellow is of wordt vastgelegd, zal als vertrouwelijk worden behandeld en zal niet zonder voorafgaande schriftelijke toestemming van de andere
                aan derden worden verstrekt.
            </li>
            <li>
                Gebruikers zijn zelf verantwoordelijk voor het lezen en tot zich nemen van onze algemene voorwaarden en privacy statement voor het correct aanleveren van gegevens,
                voor het correct bedienen van de geboden omgeving, voor het tijdig melden van fouten en tekortkomingen van de geboden omgeving, voor het maken en bewaren van
                backups van de door hen aangeleverde data en van de responses van de geboden omgeving, voor de veiligheid en beveiliging van de eigen ICT-infrastructuur, voor
                toegang tot de geboden omgeving, voor het binnen de wettelijke termijnen bewaren van gegevens die nodig zijn om aan de wettelijke bepalingen te voldoen en zich te
                houden aan de wettelijke kaders voor het gebruik en distributie van gegevens.
            </li>
            <li>
                Je mag in Tellow geen handelingen verrichten die in strijd zijn met deze algemene voorwaarden, de Wet, met de openbare orde of met de geldende normen en waarden
                ('goede zeden'). Dat betekent onder andere dat je Tellow niet gebruikt voor illegale activiteiten, opslag van illegaal of onrechtmatig materiaal, het versturen van
                spam, jezelf voordoen als medewerker van Tellow en geen virussen of andere kwaadwillende codes op Tellow mag plaatsen of verspreiden. Indien wij constateren dat
                Tellow wordt gebruikt op een wijze die niet in lijn is met wet- en regelgeving, dan hebben wij het recht om je account te blokkeren en een melding te maken bij de
                autoriteiten.
            </li>
        </ol>

        <h2>3. Onze diensten</h2>
        <ol>
            <li>
                Je kunt via Tellow gebruikmaken van de volgende diensten:
                <br />
                a) &nbsp; raadplegen van saldo en transacties van je bankrekeningen en verwachtingen voor de toekomst;
                <br />
                b) &nbsp; uitgaande facturen opstellen;
                <br />
                c) &nbsp; inkomende bonnen en facturen laten inscannen;
                <br />
                d) &nbsp; bonnen en facturen (zowel inkomend als uitgaand) laten administreren;
                <br />
                e) &nbsp; automatisch opstellen van een balans en winst- &amp; verliesrekening;
                <br />
                f) &nbsp; automatisch opstellen van een btw rapportage en belastingaangifte.
            </li>
            <li>Via partners bieden wij ook aanvullende diensten aan. Zie daarvoor artikel 7.</li>
            <li>
                Wij ondersteunen je met informatie en instructiefilmpjes op de website. Daarnaast is er tijdens kantooruren een helpdesk beschikbaar. Ons doel is dat klanten zeer
                tevreden zijn over Tellow. Ons streven is om binnen één werkdag te reageren op vragen en opmerkingen van gebruikers.
            </li>
            <li>Bovenstaande diensten worden op de website nader omschreven. Wij mogen diensten wijzigen en beëindigen en nieuwe diensten toevoegen.</li>
            <li>
                Tegen een extra vergoeding kunnen we je assisteren bij bepaalde boekhoudactiviteiten, zoals het opstellen van de btw-rapportage en belastingaangifte, maar je bent
                zelf degene die de documenten moet controleren en versturen. Je draagt hiervoor zelf de volledige verantwoordelijkheid. Indien je gebruik wenst te maken van onze
                assistentie bij je boekhouding, ben je er tevens voor verantwoordelijk om alle benodigde en juiste gegevens/documenten volledig en correct in Tellow in te voeren.
            </li>
            <li>
                Als je je als gebruiker aanmeldt gedurende of net na het einde van het boekjaar, moet je alle gegevens, facturen, bonnetjes etc. voor dat boekjaar in Tellow
                uploaden. Indien dit niet (volledig) gebeurt, en wij daardoor extra werkzaamheden moeten verrichten om je te kunnen assisteren bij de boekhouding, kunnen wij
                hiervoor extra kosten in rekening brengen.
            </li>
            <li>
                Verder moet je aan de volgende deadlines voldoen:
                <br />
                1.) &nbsp; het boekjaar moet uiterlijk op 1 maart worden afgesloten;
                <br />
                2.) &nbsp; een machtiging voor het assisteren bij / doen van een aangifte van inkomstenbelasting moet uiterlijk op 1 maart aan ons worden afgegeven;
                <br />
                3.) &nbsp; aanvullende documentatie voor een aangifte van inkomstenbelasting moet uiterlijk op 1 april bij ons worden aangeleverd;
                <br />
                4.) &nbsp; een concept aangifte van inkomstenbelasting moet uiterlijk op 15 april worden goedgekeurd.
                <br />
                Indien één of meerdere van deze deadlines worden overschreden, en wij daardoor extra werkzaamheden moeten verrichten om je te kunnen assisteren bij de boekhouding,
                kunnen wij hiervoor extra kosten in rekening brengen. Jij draagt de volledige verantwoordelijkheid voor het voldoen aan de door ons gestelde deadlines (zoals
                hierboven vermeld) én de formele deadlines van de betreffende autoriteit/partij. Wij kunnen niet aansprakelijk worden gehouden voor eventuele schade die ontstaat
                door het niet halen van een deadline, zoals het niet tijdig indienen van een belastingaangifte.
            </li>
            <li>
                Tellow ondersteunt alleen het factuurstelsel. Dit betekent dat alle inkoop- en verkoopfacturen door Tellow worden geboekt op de factuurdatum. Als er voor jou een
                wettelijke verplichting is om het kasstelsel te gebruiken, dan is Tellow helaas geen goede oplossing voor je.{' '}
            </li>
            <li>
                Tellow ondersteunt alleen de administratie van ondernemers die volledig belaste óf volledig vrijgestelde prestaties leveren. Gedeeltelijk belaste aankopen en
                uitgaven worden niet ondersteund.
            </li>
            <li>
                Je bent en blijft zelf verantwoordelijk voor alles wat je in en via Tellow doet en wat in Tellow wordt opgemaakt. Dit geldt dus ook voor het boeken van jouw
                uitgaven en voor de juistheid en tijdigheid van de btw-aangifte. Controleer alles dus steeds goed!
            </li>
        </ol>

        <h2>4. Raadplegen van saldo en transacties van jouw rekening-courant en verwachtingen</h2>
        <ol>
            <li>
                Je kunt jouw rekening-courant doorgeven in Tellow. Afhankelijk van jouw bank kun je vervolgens automatisch of handmatig transacties importeren. Afhankelijk van jouw
                bank en de opties die je kiest kan het zijn dat een derde partij (bijvoorbeeld AIIA Mastercard) Tellow van de technische integratie met de bank voorziet. Dan hebben
                we alleen invloed op de werking van de API met deze derde partij, geen invloed op de programmatuur die deze derde partij gebruikt. In het geval van storingen van
                banken of derde partijen, zijn we daar niet verantwoordelijk of aansprakelijk voor.
            </li>
            <li>
                In Tellow worden verwachtingen voor de toekomst gegeven op basis van door jou ingevoerde facturen en bonnen, met andere zaken wordt geen rekening gehouden. Het kan
                dus zijn dat de verwachtingen niet uitkomen. Houd hier rekening mee.
            </li>
            <li>
                De informatie die beschikbaar is via Tellow, kan afwijken van de informatie die beschikbaar is via andere online diensten (denk daarbij aan de bankomgeving, zoals
                Online/Internet Bankieren (Professional). Bij verschillen is de informatie die je via Online/Internetbankieren (Professional) kunt raadplegen leidend.
            </li>
        </ol>

        <h2>5. Informatie op de website en in de App</h2>
        <ol>
            <li>
                De informatie die in Tellow wordt aangeboden, is afkomstig van bronnen die betrouwbaar mogen worden geacht. Hoewel de informatie zorgvuldig is samengesteld, staan
                wij niet in voor de juistheid, volledigheid en actualiteit ervan. Je bent zelf verantwoordelijk voor de keuze en het gebruik van de informatie.{' '}
            </li>
            <li>Tellow kan soms tijdelijk buiten gebruik gesteld worden om onderhoud te plegen, aanpassingen te doen of de werking te verbeteren.</li>
        </ol>

        <h2>6. Vergoedingen diensten</h2>
        <ol>
            <li>
                Je betaalt voor de diensten elke maand vooraf een vergoeding. Op de website staat hoe deze vergoeding wordt berekend. De betalingsverplichting gaat in op de dag de
                overeenkomst tot stand komt. Na de eerste betaling, genoemd in artikel 6.7 van deze algemene voorwaarden, volgt iedere maand automatisch een nieuwe afschrijving en
                nieuwe factuur.
            </li>
            <li>De genoemde vergoedingen voor de diensten zijn altijd exclusief btw (21%).</li>
            <li>
                Wij mogen de vergoeding voor onze diensten wijzigen. Ook de periode waarover en de datum waarop je de vergoeding moet betalen, mag gewijzigd worden. Wanneer wij dit
                doen, ontvang je ten minste één maand van tevoren bericht hierover. Ben je het niet eens met een prijsverhoging, dan kun je de overeenkomst opzeggen per de datum
                dat de prijsverhoging ingaat.
            </li>
            <li>
                Betaling geschiedt via een van de twee onderstaande manieren, afhankelijk van waar de gebruiker de betaalmuur benadert:
                <ul>
                    <li>
                        via de web-versie van Tellow of via de Android-app betalen gebruikers via Stripe. De eerste keer in Stripe wordt akkoord gevraagd voor het automatisch mogen
                        incasseren van de kosten voor Tellow. De eerste betaling geldt als akkoord op het maandelijks af mogen schrijven (SEPA incasso machtiging). Betaling
                        geschiedt vooraf.
                    </li>
                    <li>
                        via de iOS-app betalen gebruikers via het in-app payment systeem van Apple app store. Hierbij is jouw gewenste betaalmethode gekoppeld aan de app store en
                        geef je als gebruiker toestemming voor het maandelijks afschrijven van de kosten. Betaling geschiedt vooraf.
                    </li>
                </ul>
            </li>
            <li>De eerste automatische incasso vindt plaats rond de dag van het afsluiten van het abonnement en wordt daarna maandelijks herhaald.</li>
            <li>
                Indien een incasso niet uitgevoerd kan worden of wordt gestorneerd, dan mogen wij, maar zijn wij daartoe niet verplicht, nog minimaal één incassopoging doen binnen
                10 werkdagen. In ieder geval zal de factuur waarop de incasso betrekking heeft binnen 14 dagen alsnog door jou voldaan moeten worden aan ons.
            </li>
            <li>
                Indien je één of meerdere verplichtingen jegens ons niet nakomt, waaronder het niet tijdig betalen van een factuur, dan hebben wij het recht om de nakoming van onze
                verplichtingen direct op te schorten en kunnen we onder andere je account blokkeren. Het abonnement blijft in dat geval doorlopen en de daaraan verbonden kosten
                blijf je onverminderd verschuldigd. Daarnaast hebben we in dat geval het recht om de overeenkomst te ontbinden en je account te verwijderen, onverminderd het recht
                op schadevergoeding, zonder eerst een ingebrekestelling te sturen.
            </li>
            <li>
                Wij gaan uit van 'fair use' door de gebruikers van Tellow. Met andere woorden, als je bovenmatig veel bonnen en/of facturen ({'>'}100 per maand) zou willen
                verwerken via Tellow, zijn wij gerechtigd hiervoor een verhoging van de kosten aan jou in rekening te brengen. Bij een overstap naar Tellow is het mogelijk om
                bestanden via een 'bulk-upload' aan te leveren. Hier zullen extra kosten aan verbonden zijn. Informeer hiernaar bij ons Tellow Support (support@tellow.nl).
            </li>
            <li>
                Indien de kosten voor Tellow niet geïncasseerd kunnen worden, of indien de incasso wordt gestorneerd, zullen de wettelijke handelsrente en incassokosten op jou
                verhaald worden, welke direct verschuldigd zijn vanaf de datum dat de betalingstermijn van de factuur verloopt. De incassokosten bedragen 15% van het openstaande
                bedrag. Indien je een incasso storneert ben je een direct opeisbare boete van EUR 7,50 per stornering aan ons verschuldigd (in lijn met de kosten die ons door de
                stornering worden opgelegd). Eventuele andere kosten, zoals kosten voor een advocaat of deurwaarder komen ook voor jouw rekening.
            </li>
            <li>
                Jaarlijkse abonnementen worden aangegaan voor 12 maanden, vanaf de datum van aanschaf (“upgrade”). Jaarlijkse abonnementen worden automatisch met 12 maanden
                verlengd aan het einde van de abonnementsperiode. Jaarlijkse abonnementen kunnen op ieder moment worden opgezegd tot 1 dag vóór datum van de jaarlijkse verlenging.
                Na opzegging loopt het abonnement door totdat het abonnementstermijn is afgelopen.
            </li>
        </ol>

        <h2>7. Gebonden aan handelingen, juridische relatie met anderen</h2>
        <ol>
            <li>
                Wij gaan ervan uit dat alles wat vanuit Tellow met jouw inloggegevens gebeurt door jouzelf is gedaan. En dat alles wat gebeurt met de inloggegevens van degene aan
                wie je een volmacht hebt gegeven, namens jou gebeurt. Dit betekent dat je gebonden bent aan alle handelingen die vanuit Tellow worden gedaan met jouw inloggegeven
                of de inloggegevens van degene aan wie je een volmacht hebt gegeven (ook als je daarvan geen kennis hebt).
            </li>
            <li>
                Wij zijn geen partij bij jouw juridische relatie met anderen. Bijvoorbeeld wanneer je iemand een factuur stuurt via Tellow of een btw-rapportage/aangifte naar de
                Belastingdienst stuurt via Tellow.
            </li>
            <li>
                Wij zijn niet aansprakelijk voor (aanvullende) diensten die die door onze partners (derden) via Tellow aan jou worden verleend, waaronder de diensten van Online
                Payment Platform (zie artikel 7.4 hieronder) en Swan (zie artikel 7.5 hieronder). Indien je van dergelijke aanvullende diensten gebruik maakt, kan het mogelijk zijn
                dat de betreffende partners voor bepaalde extra diensten (die buiten het standaard dienstenpakket vallen) rechtstreeks extra kosten in rekening brengen. De
                afspraken daarover zijn opgenomen in de overeenkomst die je rechtstreeks sluit met de betreffende partners en waar wij geen partij bij zijn.
            </li>
            <li>
                Wij maken het voor jou mogelijk om gebruik te maken van de diensten van Online Payment Platform, zodat het voor jouw klanten mogelijk is om jouw facturen online te
                betalen. Indien je gebruik maakt van deze diensten van Online Payment Platform ga je een overeenkomst aan direct met Online Payment Platform. Wij zijn geen partij
                bij jouw juridische relatie met Online Payment Platform en zijn niet aansprakelijk voor eventuele schade die voortvloeit uit de diensten die door Online Payment
                Platform aan jou zijn verricht.
            </li>
            <li>
                Wij maken het voor jou mogelijk om een bankrekening te openen bij Tellow door gebruik te maken van de diensten van Swan, zodat je je bankzaken en administratie met
                elkaar kunt integreren. Indien je gebruik maakt van deze diensten, ga je een overeenkomst aan direct met Swan. Wij zijn geen partij bij jouw juridische relatie met
                Swan en zijn niet aansprakelijk voor eventuele schade die voortvloeit uit de diensten die door Swan aan jou zijn verricht.
            </li>
        </ol>

        <h2>8. Volmacht</h2>
        <ol>
            <li>
                Je kan via de website iemand volmacht geven om Tellow namens jou te gebruiken. Handelingen van de gevolmachtigde in Tellow, gelden als handelingen van jou. Wij zijn
                niet verplicht een gevolmachtigde te accepteren.
            </li>
            <li>
                Wil je de volmacht beëindigen, doe dit dan ook via de website. Handelingen die de gevolmachtigde kort nadat je de volmacht hebt beëindigd in Tellow doet en die wij
                redelijkerwijs niet tegen kunnen houden, mogen wij toestaan. Een volmacht eindigt niet als je overlijdt of onder curatele wordt gesteld.
            </li>
        </ol>

        <h2>9. Beschikbaarheid van de diensten</h2>
        <ol>
            <li>
                Wij zullen ons naar beste kunnen inspannen om je de diensten te leveren. Wij kunnen helaas niet garanderen dat Tellow altijd beschikbaar is en altijd zonder
                storingen functioneert. Je moet hier rekening mee houden en zo nodig maatregelen nemen om de gevolgen daarvan te voorkomen of te beperken, bijvoorbeeld door een
                btw-rapportage ruim op tijd naar de Belastingdienst te versturen en niet te wachten tot de laatste dag dat dat kan.
            </li>
            <li>
                Wij mogen één of meer diensten geheel of gedeeltelijk buiten werking stellen, bijvoorbeeld voor onderhoud, bij fraude of een vermoeden van fraude. Maar bijvoorbeeld
                ook in het geval van een storing in de apparatuur of programmatuur.
            </li>
            <li>
                Wij mogen een toegangscode of een toestel(registratie) blokkeren als wij dat nodig vinden, bijvoorbeeld vanwege de veiligheid, niet-toegestaan gebruik, fraude of
                een vermoeden van fraude.
            </li>
            <li>Je bent er zelf verantwoordelijk voor om de gegevens die je nodig hebt vanuit Tellow regelmatig over te zetten op een eigen gegevensdrager.</li>
            <li>
                Tijdelijk verminderde toegang tot of beschikbaarheid van de diensten geven je geen recht op opschorting van betaling van vergoedingen of op teruggave van betaalde
                vergoedingen.
            </li>
            <li>De status (e.g. uptime) van Tellow is te zien op status.tellow.nl</li>
            <li>Tellow is niet verantwoordelijk of aansprakelijk voor de beschikbaarheid van aanvullende diensten als bedoeld in artikel 7.</li>
        </ol>

        <h2>10. Privacy</h2>
        <ol>
            <li>
                Tellow slaat persoonsgegevens op, in een eigen database beheerd door Tellow. Dit zijn jouw persoonsgegevens en die van jouw klanten, leveranciers en/of
                contactpersonen. Daarom moeten wij voldoen aan de Algemene Verordening Gegevensbescherming (AVG).
            </li>
            <li>
                Tellow gebruikers zijn zelf verantwoordelijk voor de verwerking van de genoemde persoonsgegevens en zodoende ben jij de verwerkingsverantwoordelijke. Jij schakelt
                ons in om hierbij te helpen en zodoende zijn wij de verwerker. Door akkoord te gaan met deze algemene voorwaarden verstrek je ons deze opdracht. Dit artikel 10
                wordt gekwalificeerd als een verwerkersovereenkomst.
            </li>
            <li>Als je meer wilt weten wat we met je gegevens doen en welke rechten je hebt rond je privacy, lees dan de privacyverklaring.</li>
            <li>
                Jij bent verantwoordelijk voor het invoeren, opslaan en beheren van persoonsgegevens. Daarbij moet je voldoen aan wettelijke eisen. Je garandeert dat de inhoud, het
                gebruik en de opdracht tot de verwerking van de persoonsgegevens, zoals bedoeld in de overeenkomst, niet onrechtmatig is en geen inbreuk maakt op enig recht van
                derden. Wij zijn enkel verantwoordelijk voor de verwerking van de persoonsgegevens onder deze verwerkersovereenkomst, overeenkomstig jouw instructies en onder jouw
                (eind)verantwoordelijkheid.
            </li>
            <li>Je blijft als gebruiker eigenaar van de door jouw aangeleverde data. Wij zullen nooit het eigendom claimen van gegevens die je zelf hebt ingevoerd.</li>
            <li>
                Persoonsgegevens welke door jou in Tellow ingevoerd worden, zullen niet aan derden ter beschikking worden gesteld, tenzij dit anders is bepaald bij wet of
                overeenkomst. Persoonsgegevens zullen niet naar landen buiten de Europese Unie worden gestuurd.
            </li>
            <li>
                Wij en jijzelf dragen zorg dat alle personen die toegang hebben tot persoonsgegevens deze geheimhouden en niet zullen delen. Deze geheimhoudingsplicht is niet van
                toepassing voor zover je toestemming aan ons hebt gegevens om de informatie aan derden te verschaffen, indien het verstrekken van de gegevens aan derden
                logischerwijs noodzakelijk is gezien de aard van de verstrekte opdracht en de uitvoering van de overeenkomst, of indien er een wettelijke verplichting bestaat om de
                gegevens aan derden te verstrekken.
            </li>
            <li>
                Wij verbinden ons onder de voorwaarden van deze verwerkersovereenkomst in opdracht van jou om persoonsgegevens te verwerken. Verwerking zal uitsluitend plaatsvinden
                in het kader van de uitvoering van de levering van de diensten in het kader van de onderliggende overeenkomst en de doeleinden die daarmee redelijkerwijs
                samenhangen of met jouw instemming worden bepaald. Wij zullen de persoonsgegevens niet voor enig ander doel verwerken dan zoals door jou is vastgesteld.
            </li>
            <li>
                Wij zullen zorgdragen voor de naleving van de toepasselijke wet- en regelgeving, waaronder de wet- en regelgeving op het gebied van de bescherming van
                persoonsgegevens. De verplichtingen die uit deze verwerkersovereenkomst voortvloeien gelden ook voor degenen die persoonsgegevens verwerken onder ons gezag,
                waaronder werknemers. Wij kunnen andere verwerkers inschakelen, in dat geval zal je hiervan vooraf op de hoogte worden gesteld.
            </li>
            <li>
                Wij zullen ons inspannen om voldoende technische en organisatorische maatregelen, zoals bedoeld in artikel 32 van de AVG, te nemen met betrekking tot de te
                verrichten verwerking van persoonsgegevens, tegen verlies of tegen enige vorm van onrechtmatige verwerking (zoals onbevoegde kennisname, aantasting, wijziging of
                verstrekking van de persoonsgegevens). Wij hebben bijvoorbeeld een beveiligde SSL-verbinding en toegangscontrole door middel van wachtwoorden. Wij staan er niet
                voor in dat de beveiliging onder alle omstandigheden doeltreffend is.
            </li>
            <li>
                Om beveiligingsredenen kunnen wij (historische) gegevens vasthouden inzake het gebruik van Tellow door jou alsmede over het gebruik van internet, zoals IP-adressen,
                apparaten, toestellen, software en sessies met onze systemen. Deze gegevens gebruiken wij onder meer voor het verbeteren van de veiligheid van Tellow en om de
                diensten zo goed mogelijk in te richten/te kunnen verbeteren.
            </li>
            <li>
                In het geval van een beveiligingsinbreuk en/of een datalek in de zin van artikel 33 van de AVG zullen wij jou daarover zonder onredelijke vertraging informeren. Wij
                zullen jou waar mogelijk bijstaan in het nakomen van jouw verantwoordelijkheden jegens de toezichthoudende autoriteit en/of betrokkene zoals bedoeld in artikel 33
                en 34 van de AVG.
            </li>
            <li>
                In het geval dat een betrokkene een verzoek tot inzage, rectificatie, wissing en/of beperking van de verwerking zoals bedoeld in artikel 15-19 van de AVG, richt aan
                ons, dan zullen wij dat verzoek afhandelen voor zover mogelijk en zullen wij jou van het verzoek op de hoogte stellen. Wij mogen de eventuele kosten voor de
                afhandeling van het verzoek aan jou doorbelasten.
            </li>
            <li>
                Het kan zijn dat wij derden informatie moeten geven over jou. Het kan daarbij bijvoorbeeld gaan om een toezichthouder of een andere bevoegde autoriteit die wij op
                basis van een overeenkomst of de wet informatie moeten geven. Of om een toezichthouder die jouw persoonsgegevens opvraagt in het kader van een onderzoek, tijdens of
                na het verwerken van deze gegevens. Wij kunnen verplicht zijn aan deze onderzoeken onze medewerking te verlenen. Wij hoeven het je niet te vertellen als wij een
                derde informatie geven en het kan zelfs zijn dat wij tot geheimhouding verplicht zijn. Wij geven ook gegevens door aan onze zakelijke partners/leveranciers als dat
                nodig is om de afspraken met jou na te komen. Wij beoordelen wel eerst of deze partijen voldoende betrouwbaar zijn. Daarnaast kan deze andere partij alleen onze
                opdracht krijgen als hij bepaalde afspraken met ons maakt en aantoonbaar passende beveiligingsmaatregelen heeft genomen en geheimhouding garandeert. Wij werken
                altijd met een verwerkersovereenkomst (en eventueel NDA) met haar leveranciers. Wij verwijzen in dit kader ook naar onze privacy statement
                (https://www.tellow.nl/privacy), waarin is omschreven hoe wij omgaan met jouw persoonlijke gegevens. Gegevens worden niet doorverkocht aan andere partijen.
            </li>
            <li>
                In het geval van aansprakelijkheid voor schade als gevolg van een toerekenbare tekortkoming in nakoming van de verwerkersovereenkomst, gelden de bepaling in artikel
                12 van deze algemene voorwaarden.
            </li>
            <li>
                De verwerkersovereenkomst komt tot stand zodra de overeenkomst is gesloten. De verwerkersovereenkomst wordt aangegaan voor de duur van de overeenkomst en eindigt
                zodra de overeenkomst eindigt. Zodra de verwerkersovereenkomst is beëindigd, worden de (persoons)gegevens dertien maanden bewaard, waarna deze worden verwijderd.
                Het is gedurende deze periode nog mogelijk om de (persoons)gegevens zelf over te zetten naar een andere gegevensdrager (bijvoorbeeld een harde schijf of in de
                cloud) om op een andere plek te bewaren en te gebruiken.
            </li>
        </ol>

        <h2>11. Intellectueel eigendom en gebruiksrecht</h2>
        <ol>
            <li>
                Alle rechten van intellectuele eigendom op Tellow behoren toe aan ons. Je verkrijgt een niet-exclusief gebruiksrecht op Tellow. Dit gebruiksrecht eindigt
                automatisch (van rechtswege) zodra de overeenkomst eindigt.
            </li>
            <li>
                Je bent verplicht de intellectuele eigendomsrechten van ons, Tellow-gebruikers en Tellow-partners te respecteren. Informatie, afbeeldingen, teksten en andere
                materialen van ons (welke op de website of app staan vermeld) mag je niet zonder toestemming publiceren/gebruiken.
            </li>
            <li>
                Het is niet toegestaan om de software van Tellow, handelsnamen of andere rechten van intellectuele eigendom van ons te gebruiken, kopiëren, wijzigen, verwijderen,
                te verhandelen of beschikbaar te stellen, of (een deel van) de software van Tellow te wijzigen of na te maken.
            </li>
            <li>
                Indien je in strijd handelt met hetgeen bepaald in artikel 11.2 en/of 11.3 van deze algemene voorwaarden, ben je aan ons een direct opeisbare boete verschuldigd van
                EUR 5.000,- (vijfduizend euro) en een aanvullende boete van EUR 500,- (vijfhonderd euro) per dag, voor iedere dag dat de overtreding voortduurt met een maximum van
                EUR 50.000,- (vijftigduizend euro).
            </li>
        </ol>

        <h2>12. Aansprakelijkheid</h2>
        <ol>
            <li>
                Wij sluiten iedere aansprakelijk uit voor schade die je lijdt door het gebruik van onze diensten, tenzij er sprake is van opzet of bewuste roekeloosheid. Indien wij
                (in uitzondering op hetgeen is bepaald in de vorige zin) aansprakelijk kunnen worden gehouden voor schade die je lijdt door het gebruik van onze diensten, dan is
                deze schade beperkt tot vergoeding van directe schade tot maximaal een bedrag gelijk aan het bedrag dat krachtens de toepasselijke
                aansprakelijkheidsverzekeringsovereenkomst in het desbetreffende geval wordt uitbetaald. Indien de aansprakelijkheid niet onder (de dekking van) de
                aansprakelijkheidsverzekering valt, dan is de schade beperkt tot vergoeding van directe schade tot maximaal een bedrag gelijk aan het totaal van de vergoedingen
                (excl. BTW) dat in maximaal de 6 maanden voorafgaande aan het schadeveroorzakende feit door jou aan ons is betaald voor het gebruik van Tellow.
            </li>
            <li>Wij zijn niet aansprakelijk voor indirecte schade, gevolgschade en/of gederfde winst.</li>
            <li>
                Wij kunnen niet aansprakelijk worden gehouden voor schade die is ontstaan door het (tijdelijk) blokkeren of verwijderen van je account, tenzij dit is te wijten aan
                opzet of bewuste roekeloosheid van ons.
            </li>
            <li>
                Wij kunnen niet aansprakelijk worden gehouden van schade die is ontstaan door uitval (het niet beschikbaar zijn) van Tellow en/of voor verlies van data door
                technische of andere storingen, tenzij dit is te wijten aan opzet of bewuste roekeloosheid van ons.
            </li>
            <li>
                Wij kunnen niet aansprakelijk worden gehouden voor eventuele schade die jij of derden mochten lijden als gevolg van ongeautoriseerd/onrechtmatig gebruik van jouw
                Tellow account door derden, tenzij dit is te wijten aan opzet of bewuste roekeloosheid van ons.
            </li>
            <li>
                Wij stellen Tellow beschikbaar zodat je zelf gemakkelijk je boekhouding kan doen. Wij kunnen eventueel assisteren bij je boekhouding, maar je bent zelf volledig
                verantwoordelijk voor de boekhoudactiviteiten die in/via Tellow worden verricht. Wij kunnen niet aansprakelijk worden gehouden voor schade die is ontstaan door
                eventueel door ons aan jou verrichte (boekhoud)diensten, tenzij dit is te wijten aan opzet of bewuste roekeloosheid van ons.
            </li>
            <li>
                Je zal ons vrijwaren tegen alle schade die wij mochten lijden als gevolg van aanspraken van derden, die verband houden met het gebruik van Tellow, waaronder maar
                niet beperkt tot aanspraken van derden (jouw werknemers daaronder begrepen) die schade lijden door handelingen die zijn verricht via jouw Tellow account.
            </li>
        </ol>

        <h2>13. Toepasselijk recht en rechter</h2>
        <ol>
            <li>Op de overeenkomst is het Nederlands recht van toepassing.</li>
            <li>Eventuele geschillen zullen uitsluitend worden voorgelegd aan de bevoegde rechter in Amsterdam.</li>
        </ol>

        <h2>14. Wijzigen algemene voorwaarden</h2>
        <ol>
            <li>
                Wij mogen deze algemene voorwaarden wijzigen. Uiteraard laten wij het je weten als dit gebeurt. Minimaal één maand voordat de wijziging ingaat, hoor je dat van ons,
                bijvoorbeeld door een melding als je inlogt in Tellow. Als je het niet met de wijziging eens bent, kun je de overeenkomst opzeggen overeenkomstig deze algemene
                voorwaarden. Als je voor de ingangsdatum van de wijziging niet reageert, heb je de wijziging aanvaard. Je wordt in ieder geval geacht de gewijzigde voorwaarden en
                eventuele nieuwe of gewijzigde diensten te hebben aanvaard als je gebruik hebt gemaakt van de nieuwe of gewijzigde dienst.
            </li>
        </ol>

        <h2>15. Overige bepalingen</h2>
        <ol>
            <li>
                Artikel 6 van de algemene voorwaarden (Vergoedingen diensten) is niet van toepassing op het gratis abonnement van Tellow. Verder kan, in afwijking van artikel 16
                van de algemene voorwaarden (Duur en einde overeenkomst), het gratis abonnement van Tellow met onmiddellijke ingang worden opgezegd.
            </li>
            <li>Je bent niet gerechtigd om rechten of plichten uit de overeenkomst aan derden over te dragen zonder onze voorafgaande schriftelijke toestemming.</li>
            <li>
                Indien een of meer bepalingen in deze algemene voorwaarden nietig is of wordt vernietigd, zullen de overige bepalingen in deze algemene voorwaarden van kracht
                blijven en zullen we in overleg treden om een vervangende bepaling overeen te komen, waarbij zoveel mogelijk het doel en strekking van de nietige dan wel
                vernietigde bepaling in acht wordt genomen.
            </li>
        </ol>

        <h2>16. Duur en einde overeenkomst</h2>
        <ol>
            <li>De overeenkomst wordt aangegaan voor onbepaalde tijd.</li>
            <li>
                Je kan de overeenkomst tegen het einde van de kalendermaand opzeggen, met inachtneming van een opzegtermijn van één maand. Je betaalt de kosten voor Tellow een
                maand vooruit. Tot het einde van de maand waarin er is opgezegd is het account dan ook nog beschikbaar/te gebruiken. Je kan de overeenkomst opzeggen door te mailen
                naar support@tellow.nl.
            </li>
            <li>
                Wij kunnen de overeenkomst op ieder moment tegen het eind van de kalendermaand opzeggen met inachtneming van een opzegtermijn van drie maanden door je een e-mail te
                sturen. Wij gebruiken hiervoor het e-mailadres dat je aan ons hebt doorgegeven.
            </li>
            <li>
                Wij mogen de overeenkomst met onmiddellijke ingang ontbinden als je de overeenkomst niet (tijdig of behoorlijk) nakomt, of indien je failliet wordt verklaard,
                surseance van betaling wordt verleend of je onderneming wordt geliquideerd of ontbonden. Wij zijn bij beëindiging van de overeenkomst nimmer gehouden tot enige
                restitutie van reeds ontvangen gelden of tot betaling van een schadevergoeding.
            </li>
            <li>
                Na beëindiging van de overeenkomst worden de door jou via Tellow ingevoerde gegevens dertien maanden bewaard. Het is gedurende deze periode nog mogelijk om alle
                data zelf over te zetten naar een andere gegevensdrager (bijvoorbeeld een harde schijf of in de cloud) om op een andere plek te bewaren en te gebruiken.
            </li>
            <li>
                Indien onze overeenkomst eindigt, zullen wij ook de partners die aanvullende diensten leveren daarover informeren. De verdere afwikkeling tussen jou en deze
                partners zal plaatsvinden overeenkomstig de overeenkomst die je met deze partners hebt gesloten.
            </li>
        </ol>

        <h2>17. Verwijderingsbeleid</h2>
        <ol>
            <li>
                Wij hanteren een verwijderingsbeleid volgens de gedragscode van de{' '}
                <a href="https://noticeandtakedowncode.nl/" target="_blank" rel="noopener noreferrer">
                    <b>ECP</b>
                </a>{' '}
                voor de omgang met onrechtmatige en/of inbreukmakende content.
            </li>
            <li>
                De verzoeker kan per e-mail een verwijderingsverzoek indienen onder vermelding van contactgegevens, een URL naar onrechtmatige en/of inbreukmakende content, een
                motivering waarom de informatie voor verwijdering in aanmerking zou moeten komen en een motivering waarom wij de meest geschikte tussenpersoon zijn om deze zaak af
                te handelen.
            </li>
            <li>Categorisering en priorisering van het verwijderingsverzoek zal zo spoedig mogelijk plaatsvinden, maximaal binnen 14 dagen na het ontvangen van het verzoek.</li>
        </ol>
    </TextContainer>
)

export default TermsNovember2022
