import React from 'react'
import LegalMenu from '../components/legalMenu'
import Meta from '../components/layout/meta'
import { Layout } from '../components/layout'
import { LegalContent, StickyLegalMenu } from '../utils'
import TermsNovember2022 from '../components/terms/november-2022'

class TermsPage extends React.Component {
    render() {
        return (
            <Layout>
                <Meta title="Tellow - Algemene Voowaarden" description="Tellow's algemene voorwaarden" path="/voorwaarden" />
                <LegalContent>
                    <StickyLegalMenu>
                        <LegalMenu className="sticky" />
                    </StickyLegalMenu>
                    <TermsNovember2022 />
                </LegalContent>
            </Layout>
        )
    }
}

export default TermsPage
